(function () {
  'use strict';

  class DiscussionViewCtrl {
    constructor(Discussions, User, Groups, Modules, ActionCableChannel, $rootScope, $scope, $filter, Utils, $document, Media, ActionCableSocketWrangler, $state, $mdToast, CommentZoom, $stateParams) {
      let vm = this;
      vm.Discussions = Discussions;
      vm.User = User;
      vm.Modules = Modules;
      vm.$document = $document;
      vm.Groups = Groups;
      vm.Utils = Utils;
      vm.CommentZoom = CommentZoom;
      vm.Media = Media;
      vm.$state = $state;
      vm.$stateParams = $stateParams;
      vm.$mdToast = $mdToast;
      vm.$filter = $filter;
      vm.counter = 0;
      vm.ActionCableChannel = ActionCableChannel;
      vm.ActionCableSocketWrangler = ActionCableSocketWrangler;
      vm.$rootScope = $rootScope;
      vm.currentUser = vm.$rootScope.user;
      vm.$scope = $scope;
      vm.ctrlName = 'DiscussionsViewCtrl';
      $scope.$on('$destroy', ()=> {
        if (vm.DiscussionChannel && vm.DiscussionChannel.unsubscribe) {
          vm.DiscussionChannel.unsubscribe();
          if (ActionCableSocketWrangler.connected && !vm.$state.includes('module.resource')) {
            ActionCableSocketWrangler.stop();
          }
        }
      });
      vm.init();
    }
    init() {
      let vm = this;
      if (vm.Utils.roleCheck(['Teacher']) || vm.Utils.roleCheck(['admin']) || vm.Utils.roleCheck(['superadmin'])) {
        let id = vm.Utils.getClassroom() || vm.$stateParams.classroom_id || vm.$rootScope.classroomId;
        vm.discussionView(id);
      } else if (vm.currentUser.classroom_ids && vm.currentUser.classroom_ids.length > 0) {
        vm.user = vm.currentUser;
        vm.discussionView(vm.currentUser.classroom_ids[0]);
      }
    }
    sendComment(form) {
      let vm = this;
      if (form.$valid) {
        if (vm.$scope.resource && vm.$scope.resource[0].type === 'canvas') {
          vm.setCanvas();
        } else {
          vm.sendChannel();
        }
      }
    }
    setCanvas() {
      let vm = this;
      vm.canvas = vm.$document[0].getElementById('canvas');
      vm.ctx = vm.canvas.getContext('2d');
      vm.uploadMedia(vm.Utils.dataURItoBlob(vm.canvas.toDataURL('image/png')));
    }
    uploadMedia(file) {
      const vm = this;
      vm.Media.mediaCreate(file)
        .then((data)=>{
          vm.sendChannel(data.file.url);
        });
    }
    sendChannel(url) {
      let vm = this,
          obj = {content: vm.reply};
      if (!vm.Utils.roleCheck(['Teacher'])) {
        obj.group_id = vm.currentUser.groups[0].id;
      }
      if (url) {
        obj.url = url;
      }
      if (vm.$scope.tags) {
        obj.tags = vm.$scope.tags;
      }
      vm.DiscussionChannel.send(obj, 'create_entry');
      vm.reply = '';
      vm.message('Your entry has been submitted.');
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    zoom(e, comment) {
      let vm = this;
      vm.CommentZoom.show(e, comment, vm.fullEntries);
    }
    replyComment(id, reply) {
      let vm = this;
      if (reply) {
        vm.DiscussionChannel.send({content: reply, discussion_entry_id: id}, 'add_a_reply');
      }
    }
    getResource() {
      let vm = this;
      vm.Modules.resourceNodesIndex({
        resource_node_id: vm.discussionObject.discussion.object_id,
        id: vm.discussionObject.discussion.module_id
      })
        .then((data)=> {
          vm.resourceNode = data.resource_node;
        });
    }
    actionCable(classroomId) {
      let vm = this,
          channelObj = {
            discussion_id: vm.discussionObject.discussion.id,
            classroom_id: classroomId,
            token: vm.currentUser.token
          },
          currentComment,
          comments = vm.entries.documents;
      if (!vm.ActionCableSocketWrangler.connected && !vm.ActionCableSocketWrangler.connecting) {
        vm.ActionCableSocketWrangler.start();
      }
      vm.DiscussionChannel = new vm.ActionCableChannel('DiscussionChannel', channelObj);
      vm.DiscussionChannel.subscribe(function (data) {
        if (!vm.$scope.isTeacher) {
          if (data.type === 'reply') {
            currentComment = vm.$filter('filter')(comments, function (d) {
              return data.entry.id === d.id;
            });
            comments[comments.indexOf(currentComment[0])] = data.entry;
          } else {
            comments.unshift(data.entry);
          }
        }
      });
    }
    discussionView(classroomId) {
      let vm = this;
      vm.Discussions.discussionView({id: vm.$scope.id, classroom_id: classroomId || ''})
        .then((data) => {
          vm.discussionObject = data;
          vm.entriesView(classroomId, true);
        });
    }
    entriesView(classroomId, firstime, page) {
      let vm = this,
          payload = {id: vm.discussionObject.discussion.id, classroom_id: classroomId || '', rows: 50, page: page || 1};
      if (vm.$scope.isTeacher) {
        vm.entries = {
          documents: [vm.$scope.isTeacher.entry]
        };
        return;
      }
      if (!vm.Utils.roleCheck(['Teacher'])) {
        payload.group_id = vm.currentUser.groups[0].id;
      }
      vm.Discussions.entriesAllView(payload)
        .then((data) => {
          if (!vm.entries) {
            vm.entries = data;
          } else {
            Array.prototype.push.apply(vm.entries.documents, data.documents);
            vm.entries.page = data.page;
            vm.entries.pages = data.pages;
            vm.entries.found = data.found;
          }
          if (firstime) {
            vm.classroomId = classroomId;
            vm.actionCable(classroomId);
            vm.getResource();
          }
        });
    }
  }

  /**
   * @ngdoc object
   * @name discussions.view.controller:DiscussionViewCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('DiscussionViewCtrl', DiscussionViewCtrl);
}());
